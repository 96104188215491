@import "../common/variables";
@import "../common/mixin";

.visual {
  &-area {
    width: 100%;
    height: 100dvh;

    @media screen and (min-width:576px) {
      height: 100vh;
    }

    &-inner {
      max-width: 1200px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
    }

    .scroll-btn {
      @include position(absolute, null, null, 20px, 50%);
      transform: translateX(-50%);
      text-align: center;

      @media screen and (min-width:768px) {
        bottom: 20px;
      }

      &-link {
        display: inline-block;
        letter-spacing: 2px;
      }

      &-text {
        font-size: 14px;
        color: var(--fill);
      }

      .mouse {
        position: relative;
        display: block;
        width: 25px;
        height: 40px;
        box-sizing: border-box;
        border: 2px solid var(--fill);
        border-radius: 23px;

        span {
          @include position(absolute, 29%, null, null, 50%);
          width: 8px;
          height: 8px;
          margin: -4px 0 0 -4px;
          background: var(--fill);
          border-radius: 50%;
          animation: ani-mouse 2.5s linear infinite;
        }
      }
    }
  }

  &-title {
    display: block;

    @media screen and (min-width:992px) {
      width: 500px;
    }
  }

  &-text {
    font-size: 14px;

    @media screen and (min-width:768px) {
      font-size: 16px;
    }

    @media screen and (min-width:1200px) {
      font-size: 20px;
    }

    &-wrap {
      text-align: center;
      @include position(absolute, 50%, null, null, 50%);
      transform: translate(-50%, -50%);
    }
  }
}

.ellipses {
  border-radius: 50%;
  @include position(absolute, 0);
  border-style: solid;

  &-container {
    width: 85vw;
    height: 85vw;
    border-radius: 50%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);

    @media screen and (min-width:576px) {
      width: 70vw;
      height: 70vw;
    }

    @media screen and (min-width:768px) {
      width: 54.17vw;
      height: 54.17vw;
    }

    @media screen and (min-width:992px) {
      width: 650px;
      height: 650px;
    }
  }

  &-outer {
    &-thin {
      width: 100%;
      height: 100%;
      border-width: 1px;
      border-color: rgba(9, 56, 62, 0.1);
      animation: ellipsesOrbit 15s ease-in-out infinite;
    }

    &-thick {
      width: 99.5%;
      height: 99.5%;
      border-color: var(--text) transparent;
      border-width: 2px;
      transform: rotate(-45deg);
      animation: ellipsesRotate 15s ease-in-out infinite;
    }
  }

  &-orbit {
    display: none;
    border-color: var(--text);

    @media screen and (min-width:992px) {
      display: inline-block;
      top: 50px;
      right: 110px;
      width: 30px;
      height: 30px;
    }

    &:before {
      border-radius: 50%;
      background-color: var(--text);
      margin: 0 auto;
      @include position(absolute, 50%, 0, null, 0);
      transform: translateY(-50%);
      content: '';

      @media screen and (min-width:992px) {
        width: 16px;
        height: 16px;
      }
    }
  }

  &-coords {
    @include position(absolute, null, null, 20px, 0);
    transform: rotate(-90deg) translateX(50%);
    display: none;
    z-index: 100;
    vertical-align: top;

    &-image {
      width: 200px;
      height: auto;
      vertical-align: top;
    }

    @media screen and (min-width:1200px) {
      display: inline-block;
    }
  }
}

@keyframes ellipsesRotate {
  0% {
    transform: rotate(-45deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes ellipsesOrbit {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }

  15% {
    opacity: 1;
    top: 50%;
  }

  50% {
    opacity: 0;
    top: 50%;
  }

  100% {
    opacity: 0;
    top: 29%;
  }
}