:root {
  --background: transparent;
  --white: #fff;
  --text: #222;
  --point-color: #b34831;
  --border: #ccc;
  --fill: #222;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #222;
    --white: #222;
    --text: #efefef;
    --point-color: #ffae00;
    --border: #999;
    --fill: #fff;
  }
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  letter-spacing: -.2px;
  font-size: 14px;
  line-height: 1.5;
  word-break: keep-all;
  font-family: 'Inter', 'Pretendard-Regular', sans-serif;
  background: var(--background);
  color: var(--text);

  &.light {
    --background: transparent;
    --white: #fff;
    --text: #222;
    --point-color: #b34831;
    --border: #ccc;
    --fill: #222;
  }

  &.dark {
    --background: #222;
    --white: #222;
    --text: #efefef;
    --point-color: #ffae00;
    --border: #999;
    --fill: #fff;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

dl,
dd {
  margin: 0;
}

a {
  color: var(--text);
  text-decoration: none;
}

button {
  border-radius: 0;
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
}

svg {
  fill: var(--fill);
}

dialog {
  padding: 0;
  box-sizing: border-box;
}

textarea {
  resize: none;
  padding: 0;
  font-family: inherit;
  box-sizing: border-box;
}