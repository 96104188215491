@import "../common/variables";
@import "../common/mixin";

.navigation {
  @include position(fixed, 50%, 50px);
  transform: translateY(-50%);
  z-index: 1000;
  display: none;

  @media screen and (min-width:1200px) {
    display: block;
  }

  &-list {
    text-align: right;
  }

  &-item {
    vertical-align: middle;
    line-height: 1.5;
  }

  &-button {
    position: relative;
    padding-right: 30px;
    letter-spacing: .4px;
    font-family: "Pretendard-Bold", sans-serif;
    font-style: italic;
    color: transparent;
    transition: color .4s;

    &:hover {
      color: var(--text);
    }

    &::after {
      @include position(absolute, 50%, 0);
      transform: translateY(-50%);
      display: block;
      background-color: var(--border);
      height: 1px;
      width: 20px;
      content: '';

      @at-root .navigation-item:nth-child(even) & {
        width: 12px;
      }
    }

    @at-root .active & {
      color: var(--text);

      &::after {
        background-color: var(--text);
      }
    }
  }
}