@import "../common/variables";
@import "../common/mixin";


.footer {
  &-copyright {
    max-width: 1200px;
    width: 100%;
    border-top: 1px dashed var(--border);
    text-align: center;
    padding: 20px;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
  }
}