@import "../common/variables";
@import "../common/mixin";

.particle {
  position: absolute;
  border-radius: 50%;

  &-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    @include position(absolute, 0, 0, 0, 0);
    z-index: -1;
  }
}

@for $i from 1 through 60 {
  @keyframes particle-animation-#{$i} {
    98% {
      opacity: random(70)/100;
    }

    100% {
      transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
      opacity: 0;
    }
  }

  .particle:nth-child(#{$i}){
    animation: particle-animation-#{$i} 60s infinite;
    $size: random(10) + 20 + px;
    opacity: random(70)/100;
    height: $size;
    width: $size;
    animation-delay: -$i * .2s;
    filter: blur(2px);
    transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
    background: #aaa;
  }

  @media screen and (min-width:768px) {
    .particle:nth-child(#{$i}){
      animation: particle-animation-#{$i} 60s infinite;
      $size: random(10) + 50 + px;
      opacity: random(70)/100;
      height: $size;
      width: $size;
      animation-delay: -$i * .2s;
      filter: blur(2px);
      transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
      background: #aaa;
    }
  }
}