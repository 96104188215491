.floating-button {
    border: 1px solid var(--border);
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: var(--white);
    transition: all .2s;

    &:not(:last-child) {
        margin-bottom: 5px;
    }

    $icons: (top, mode);
    @each $icon in $icons {
        &.#{$icon} {
            @at-root .light & {
                background: var(--white) url("../../assets/icons/icon-#{$icon}.svg") no-repeat center center / 20px 20px;
            }

            @at-root .dark & {
                background: var(--white) url("../../assets/icons/icon-#{$icon}-w.svg") no-repeat center center / 20px 20px;
            }
        }
    }

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    &-container {
        position: fixed;
        right: 20px;
        bottom: 20px;
        display: flex;
        flex-direction: column;
        z-index: 1000;

        @media screen and (min-width:1300px) {
            right: 50px;
        }
    }
}