@import "../common/variables";
@import "../common/mixin";

.popup {
  @include position(fixed, 0, null, null, 0);
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: none;

  &.active {
    display: block;
  }

  &-dimmed {
    width: 100vw;
    height: 100vh;
    background-color: $dimmed;
  }

  &-close-button {
    @include position(fixed, calc(50% - 185px), 15px);
    width: 25px;
    height: 25px;
    background:  url("../../assets/icons/icon-close.svg") no-repeat center center / contain;

    @media screen and (min-width:992px) {
      top: calc(50% - 290px);
      left: calc(50% + 320px);
      width: 30px;
      height: 30px;
    }
  }

  &-visit-button {
    @include position(absolute, 25px, 25px);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff url("../../assets/icons/icon-arrow-right-link.svg") no-repeat center center / contain;
  }

  &-wrap {
    @include position(fixed, 50%, null, null, 50%);
    border-radius: 6px;
    overflow: hidden;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    color: var(--text);
    padding: 25px;
    box-sizing: border-box;
    width: calc(100% - 30px);
    height: 300px;

    @media screen and (min-width:992px) {
      width: 700px;
      height: 500px;
    }
  }

  &-inner {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  &-contents {
    width: 100%;
    height: calc(100% - 76px);
    overflow: auto;
    box-sizing: border-box;

    picture:not(:last-child) {
      img {
        margin-bottom: 15px;
      }
    }
  }

  &-title {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 30px;
    font-family: Pretendard-Bold, sans-serif;
    padding-right: 35px;
    box-sizing: border-box;
    @include ellipsis;

    @media screen and (min-width:992px) {
      font-size: 20px;
    }
  }

  &-info {
    &-list {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &-item {
      display: flex;
      align-items: center;
      font-size: 14px;

      &:not(:last-child) {
        margin-right: 17px;
        position: relative;

        &::after {
          @include position(absolute, 4px, -9px);
          width: 1px;
          height: 12px;
          background-color: var(--text);
          content: '';
        }
      }
    }

    &-title {
      margin-right: 4px;
      font-family: Pretendard-Bold, sans-serif;
    }
  }

  &-sub-desc {
    margin-bottom: 20px;
  }

  &-image {
    width: 100%;
    vertical-align: top;
  }

  &-table {
    width: 100%;
    min-width: 500px;
    border-collapse: collapse;
    border: 1px solid var(--border);
    font-size: 14px;
    box-sizing: border-box;

    &-wrap {
      margin-top: 20px;
    }

    td {
      padding: 10px;
      min-height: 40px;
      border-left: 1px solid var(--border);
      border-right: 1px solid var(--border);
      border-bottom: 1px solid var(--border);
      box-sizing: border-box;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      &.border-bottom {
        border-bottom: 1px solid var(--border);
      }
    }

    th {
      padding: 10px;
      min-height: 40px;
      background-color: var(--point-color);
      color: var(--white);
      font-weight: bold;
      font-size: 16px;
      box-sizing: border-box;
    }

    &-wrap {
      overflow-x: auto;
      box-sizing: border-box;
    }

    &-link {
      color: var(--point-color);
      border-bottom: 1px dashed var(--point-color);

      &:hover {
        background-color: var(--point-color);
        color: var(--white);
        border: none;
      }
    }
  }
}