@import "../common/variables";
@import "../common/mixin";

.header {
  @include position(fixed, 0, null, null, 0);
  width: 100%;
  z-index: 1000;

  &-inner {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;

    @media screen and (min-width:1200px) {
      padding: 30px 0;
    }
  }

  &-title {
    font-size: 20px;

    &-wrap {
      flex: 1;
    }
  }

  &-nav {
    &-list {
      display: flex;
      align-items: center;
    }

    &-item {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    &-link {
      display: block;
      width: 35px;
      height: 35px;
      overflow: hidden;
      color: transparent;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}