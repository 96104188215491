@import "../common/variables";
@import "../common/mixin";

.stack {
  &-area {
    position: relative;
  }

  &-visual-image {
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    display: none;

    @media screen and (min-width:1200px) {
      display: block;
    }
  }

  &-title {
    font-size: 32px;
    line-height: 1;
    border-bottom: 7px solid var(--text);
    text-transform: uppercase;
    display: inline-block;

    @media screen and (min-width:992px) {
      font-size: 44px;
    }

    @media screen and (min-width:1200px) {
      @include position(absolute, 350px, null, null, 10px);
    }

    .point {
      font-size: 70px;
      line-height: 32px;

      @media screen and (min-width:992px) {
        line-height: 40px;
      }
    }
  }

  &-list {
    margin-top: 20px;

    @media screen and (min-width:1200px) {
      margin: 50px 0 0 50%;
    }
  }

  &-item {
    &:not(:first-child) {
      margin-top: 30px;
    }

    &-title {
      font-size: 18px;
      font-weight: normal;
      font-family: "Pretendard-Bold", sans-serif;
      font-style: italic;
      margin-bottom: 10px;
      letter-spacing: 0.2px;
      position: relative;
      display: inline-block;

      &::after {
        width: 8px;
        height: 8px;
        background-color: var(--point-color);
        border-radius: 50%;
        @include position (absolute, null, -12px, 5px);
        content: '';
      }

      @media screen and (min-width:576px) {
        font-size: 24px;

        &::after {
          @include position (absolute, null, -12px, 10px);
        }
      }
    }

    &-desc {
      position: relative;
      padding-left: 20px;
      margin-bottom: 5px;

      &::before {
        width: 13px;
        height: 1px;
        background-color: var(--text);
        @include position(absolute, 10px, null, null, 0);
        content: '';
      }
    }

    &-icon {
      display: inline-block;
      width: 30px;
      border: 1px solid var(--border);
      border-radius: 3px;
      margin-top: 15px;

      &:not(:last-child) {
        margin-right: 5px;
      }

      &-image {
        vertical-align: top;
      }
    }
  }
}