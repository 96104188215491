@import "../common/variables";
@import "../common/mixin";

.about {
  &-area {
    @media screen and (min-width:992px) {
      display: flex;
      align-items: center;
    }
  }

  &-image-area {
    @media screen and (min-width:992px) {
      width: 50%;
    }
  }

  &-text-area {
    @media screen and (min-width:992px) {
      width: 50%;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    vertical-align: top;
    object-fit: cover;

    &-area {
      margin-bottom: 70px;
      padding-right: 30px;
      position: relative;
      box-sizing: border-box;
      height: 400px;

      @media screen and (min-width:768px) {
        height: 650px;
      }

      @media screen and (min-width:992px) {
        height: 750px;
        padding: 0;
        margin: 0 100px 0 0;
      }

      &::after {
        @include position(absolute, 30px, 0);
        z-index: -1;
        display: block;
        width: calc(100% - 30px);
        height: 100%;
        border: 2px solid var(--text);
        background-color: var(--background);
        content: '';

        @media screen and (min-width:992px) {
          right: -30px;
        }
      }
    }
  }

  &-title {
    display: inline-block;
    margin-bottom: 10px;
    font-family: "Pretendard-Bold", sans-serif;
    font-style: italic;
    font-size: 30px;
    position: relative;
    font-weight: normal;

    &::after {
      width: 8px;
      height: 8px;
      background-color: var(--point-color);
      border-radius: 50%;
      @include position (absolute, null, -12px, 10px);
      content: '';
    }
  }

  &-skill {
    &-list {
      margin-top: 20px;
    }

    &-item {
      text-transform: uppercase;
      padding: 2px 15px;
      border: 1px dashed #aaa;
      border-radius: 50px;
      display: inline-block;
      vertical-align: top;
      margin-top: 10px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &-list {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px dashed var(--text);
  }

  &-item {
    position: relative;

    &:not(:first-child) {
      margin-top: 35px;
    }

    &-title {
      @include position(absolute, 0, null, null, 0);
      max-width: 80px;
      border-bottom: 1px solid var(--text);
      font-family: Pretendard-Bold, sans-serif;
    }

    &-contents {
      padding-left: 100px;
      position: relative;
      color: #aaa;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      .point {
        color: var(--text);
        font-weight: normal;
        display: block;

        @media screen and (min-width:576px) {
          margin-right: 5px;
          display: inline;
        }
      }

      &::before {
        @include position(absolute, 10px, null, null, 85px);
        border-radius: 50%;
        display: block;
        width: 10px;
        height: 1px;
        background-color: var(--text);
        content: '';
      }
    }
  }
}